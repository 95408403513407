// Simulation Model
import { Model } from 'pinia-orm';
import { Attr, Num, Str, Uid, Bool } from 'pinia-orm/decorators';
export default class SimulationModel extends Model {
  // entity is a required property for all models. like name of database, unique
  // state.entities.simulations
  static entity = 'simulations';

  public static TypeValues = {
    Test: "test",
    Private: "private",
    Public: "public"
  }
  public static StatusValues = {
    Created: "created",
    Started: "started",
    InProgress: "in_progress",
    Done: "done",
    Failure: "failure"
  }

  public translateSimType(t: any): string {
    switch(this.type) { 
      case SimulationModel.TypeValues.Test: 
         return t('simtype.test');
      case SimulationModel.TypeValues.Private:
        return t('simtype.private');
      case SimulationModel.TypeValues.Public:
        return t('simtype.public');        
    } 
    return t('simtype.unknown');
  }
  public translateSimStatus(t: any): string {
    switch(this.status) { 
      case SimulationModel.StatusValues.Created: 
         return t('simstatus.created');
      case SimulationModel.StatusValues.Started:
        return t('simstatus.in_progress');
      case SimulationModel.StatusValues.InProgress:
        return t('simstatus.in_progress');      
      case SimulationModel.StatusValues.Done:
        return t('simstatus.done');  
      case SimulationModel.StatusValues.Failure:
        return t('simstatus.failure');                             
    } 
    return t('simstatus.unknown');
  }

  //static primaryKey = 'myId'    // set primary key other than 'id' (default)

  @Uid() declare id: number;
  @Str("Meine eigene Simulation") declare name: string;
  @Str("") declare description: string;
  @Bool(null) declare is_stable: boolean;
  @Attr("") declare type: string;
  @Str("") declare status: string;
  @Str("") declare abm_image_version: string;
  @Attr("") declare parameters_abm: any;
  @Attr(null) declare user_fk: any;
  @Num(null) declare selected_run_id: number;
  @Num(null) declare sim_runs_finished: number;
  @Attr(null) declare sim_results: any;
  @Attr(null) declare sim_run_analyses: any;
}
