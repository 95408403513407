import { createRouter, createWebHistory } from '@ionic/vue-router';
import type { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginPage.vue')
  },  
  {
    path: '/areas',
    component: () => import('@/views/RegionPage.vue')
  },
  {
    path: '/areas/:areaId/simulations',
    component: () => import('@/views/SimulationsPage.vue'),
    props: true 
  },
  {
    path: '/areas/:areaId/simulations/:simId/parameters',
    component: () => import('@/views/ParametersPage.vue'),
    props: true
  },
  {
    path: '/areas/:areaId/simulations/create',
    component: () => import('@/views/ParametersPage.vue'),
    props: true
  },
  {
    path: '/areas/:areaId/simulations/:simId/map',
    component: () => import('@/views/MapPage.vue'),
    props: true
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutPage.vue'),
    meta: { requiresLoggedIn: false}
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: () => import('@/views/ImpressumPage.vue'),
    meta: { requiresLoggedIn: false}
  },
  {
    path: '/logs',
    name: 'logs',
    component: () => import('@/views/AboutPage.vue'),
    meta: { requiresLoggedIn: true}
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

export default router
